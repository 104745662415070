// Navbar.js
import React, { useState } from 'react';
import './header.css';

const Navbar = () => {

    //FUNCTIONS
    
    const openNavBar = () => {
        document.getElementById("nav-section").style.display = "flex";
    };

    const closeNavBar = () => {
        document.getElementById("nav-section").style.display = "none";
    };

    const handleHoverElement1 = () => {
        document.getElementById("nav-item-1").style.color = "#000000"
        document.getElementById("nav-item-2").style.color = "#FFFFFF"
        document.getElementById("nav-item-3").style.color = "#FFFFFF"
        document.getElementById("nav-item-4").style.color = "#FFFFFF"
      };

      const handleHoverElement2 = () => {
        document.getElementById("nav-item-1").style.color = "#FFFFFF"
        document.getElementById("nav-item-2").style.color = "#000000"
        document.getElementById("nav-item-3").style.color = "#FFFFFF"
        document.getElementById("nav-item-4").style.color = "#FFFFFF"
      };

      const handleHoverElement3 = () => {
        document.getElementById("nav-item-1").style.color = "#FFFFFF"
        document.getElementById("nav-item-2").style.color = "#FFFFFF"
        document.getElementById("nav-item-3").style.color = "#000000"
        document.getElementById("nav-item-4").style.color = "#FFFFFF"
      };

      const handleHoverElement4 = () => {
        document.getElementById("nav-item-1").style.color = "#FFFFFF"
        document.getElementById("nav-item-2").style.color = "#FFFFFF"
        document.getElementById("nav-item-3").style.color = "#FFFFFF"
        document.getElementById("nav-item-4").style.color = "#000000"
      };

      const resetHoverEvents = () => {
        document.getElementById("nav-item-1").style.color = "#FFFFFF"
        document.getElementById("nav-item-2").style.color = "#FFFFFF"
        document.getElementById("nav-item-3").style.color = "#FFFFFF"
        document.getElementById("nav-item-4").style.color = "#FFFFFF"
      };





    //USER INTERFACE CODE
    return (
        <>
            <div className="header">
                <div className="nav-left-container"></div>
                <div className="nav-right-container">
                    <button className="nav-cta">LET'S TALK</button>
                    <img onClick={openNavBar} src='/header/hamburger-inactive.png' className="nav-icon"></img>
                </div>
            </div>

            <div className="nav-section" id="nav-section" >
                <div className="nav-section-left" onMouseEnter={resetHoverEvents}>
                    <div className="nav-section-left-row" onMouseEnter={handleHoverElement1}>
                        <label className="nav-item-bullet">01.&nbsp;</label>
                        <label className="nav-item-text" id="nav-item-1">HOME</label>
                    </div>
                    <div className="nav-section-left-row" onMouseEnter={handleHoverElement2}>
                        <label className="nav-item-bullet">02.&nbsp;</label>
                        <label className="nav-item-text" id="nav-item-2">WORK</label>
                    </div>
                    <div className="nav-section-left-row" onMouseEnter={handleHoverElement3}>
                        <label className="nav-item-bullet">03.&nbsp;</label>
                        <label className="nav-item-text" id="nav-item-3">BLOG</label>
                    </div>
                    <div className="nav-section-left-row" onMouseEnter={handleHoverElement4}>
                        <label className="nav-item-bullet">04.&nbsp;</label>
                        <label className="nav-item-text" id="nav-item-4">CONTACT</label>
                    </div>
                </div>
                <div className="nav-section-right">
                    <div className="nav-right-top-container">
                        <img onClick={closeNavBar} src='/header/hamburger-close.png' className="nav-icon"></img>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;