import './App.css';

//dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

//components
import Header from './components/header/header';
import Cursor from './components/cursor/cursor';
import Footer from './components/footer/footer';

//pages
import LandingPage from './pages/landing-page/landing-page';
import AboutPage from './pages/about-page/about-page';

function App() {
  return (
    <>
    <Header/>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </Router>
    <Footer/>
    <Cursor/>
    </>
  );
}

export default App;
