import React from 'react';
import "./landing-page.css"

//sections
import Hero from '../../components/landing/hero/hero';
import About from '../../components/landing/about/about';
import Blog from '../../components/landing/blog/blog';
import ServiceType from '../../components/landing/anim/anim'
import Footer from '../../components/footer/footer';

const LandingPage = () => {
  return (
    <>
    <Hero/>
    <About/>
    <Blog/>
    </>
  );
};

export default LandingPage;