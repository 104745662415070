import React from "react";

import "./footer.css";

function Footer() {
    return (
        <div className="footer">
            <div className="main-footer-container">
                <div className="footer-left">
                    <img src="/footer/footer-logo-1.png" alt="footer-logo" className="footer-logo" />
                    <label className="footer-text-main"><b>Rohan Christopher Tech</b></label>
                    <label className="footer-text">contact@rohanchristopher.in</label>
                    <label className="footer-text">+91 6281508679</label>
                    <label className="footer-text">502, B.G.R. Encalve, HSR Layout, Bangalore</label>
                </div>
                <div className="footer-right">
                    
                </div>
            </div>
            <br/>
            <div className="sub-footer-container">
                <label className="sub-footer-text">&copy; 2024 Rohan Christopher, All rights reserved.</label>
            </div>
        </div>
    )
}

export default Footer;