import React from "react";

import styles from './displayarea.module.css';

function DisplayArea() {
    return (
        <div className={styles.displayAreaMainContainer}>
        </div>
    )
}

export default DisplayArea;