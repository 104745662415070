// dependencies
import React from 'react';
import Marquee from "react-fast-marquee";

//css
import './hero.css'

const Hero = () => {
  return (
    <div className="section1">
      <div className="bg-lettering-container">
        <div className="bg-lettering-row">
          <Marquee direction="left" speed={150} gradient={false}> <label className="bg-letters">WebServerWordpressDevelopment</label> </Marquee>

        </div>
        <div className="bg-lettering-row">
          <Marquee direction="right" speed={150} gradient={false}><label className="bg-letters">MernDevApp</label> </Marquee>
        </div>
      </div>
      <div className="title-box">
        <div className="title-box-img-section">
          <img src="/landing/avatar-new.png" className="title-box-img" />
        </div>
        <div className="title-box-text-section">
          <label className="title-line-1">I am</label><br />
          <label className="title-line-2">ROHAN.</label>
          <div className="underline-segment"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;