import React from "react"
import "./about.css";


function About() {


    function redirectFun() {
        window.location.href = "https://www.linkedin.com/in/rohan-christopher-439234166/"
    }


    var text = "<DEVELOPER/>"
    return (
    <div id="about" className="section-3">

        <div className="section-3-left">

            <div className="section-3-image-container">
                <div className="box" >
                    <img src="/landing/profile-img.jpeg" className="about-image" />
                </div>
            </div>
        </div>

        <div className="section-3-right">
            <div className="about-content-container" >
                <label className="about-heading">ABOUT ME</label>
                <br />
                <label className="about-content">I am Rohan Christopher,</label>
                <label className="about-content">Full Stack {text} working as <b><i>Software Engineer - II</i></b> at <b>Honeywell, HTS - Aerospace Technologies</b>, I specialize in developing intriguing digital experiences with code.</label>
                <button className="resume-button" onClick={redirectFun}>Connect</button>
            </div>
        </div>
    </div>)
}

export default About;