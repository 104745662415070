import React from "react";

import styles from './sidebar.module.css';

function Sidebar() {
    return (
        <div className={styles.sidebarMainContainer}>
            <div className={styles.imgContainer}>
                
            </div>
        </div>
    )
}

export default Sidebar;