import React from "react"
import "./blog.css";


function Blog() {


    function redirectFun() {
        window.location.href = "https://blog.rohanchristopher.in"
    }

    return (
        <div id="blog" className="section-4">

            <div className="section-4-left">
                <div className="blog-content-container" >
                    <label className="blog-heading">BLOG SPOT</label>
                    <br />
                    <label className="blog-content">When I find time, I tend to write about the problems I've solved, my blog is where I post all of that content for my own reference and for anyone who has a penchant to read tech content</label>
                    <button className="resume-button" onClick={redirectFun}>Ro-Blog</button>
                </div>

            </div>

            <div className="section-4-right">

                <div className="section-4-image-container">
                    <div className="box-blog" >
                        <img src="/landing/comp-reading.jpg" className="blog-image" />
                    </div>
                </div>
            </div>
        </div>)
}

export default Blog;