import React from 'react';
import styles from './about-page.module.css';

//components
import Sidebar from '../../components/about/side-bar/sidebar';
import DisplayArea from '../../components/about/display-area/displayarea';

const AboutPage = () => {
  return (
    <>
    <div className={styles.mainContainer}>
        <Sidebar/>
        <DisplayArea/>
    </div>
    </>
  );
};

export default AboutPage;